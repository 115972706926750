import React, { Fragment, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import find from "lodash.find"
import classnames from "classnames"

/* Import Global Contexts */
import HoverContext from "~context/hover"

/* Import Global Components */
import SEO from "~components/seo"
import Img from "~components/img/img"

/* Import Local Styles */
import "./index.css"

const Index = ({
  data: {
    allSanityProject: { edges: projects },
  },
}) => {
  const [isHovered] = useContext(HoverContext)

  useEffect(() => {
    document.documentElement.style.setProperty("--color-background", "#E5D5D8")
    document.documentElement.style.setProperty("--color-foreground", "#64657A")
  }, [])

  return (
    <Fragment>
      <SEO title="Index" />
      <div className="index">
        {projects.map(project => {
          const image = find(
            project.node.images,
            image => image.__typename === "SanityImageAlt"
          )
          return image ? (
            <div
              key={project.node._id}
              className={classnames("hoverable", {
                hovered: project.node._id === isHovered,
              })}
            >
              <Img src={`${image.asset.url}?q=90&w=1200`} />
            </div>
          ) : (
            <div key={project.node._id} />
          )
        })}
      </div>
    </Fragment>
  )
}

export default Index

export const query = graphql`
  query HomepageQuery {
    allSanityProject {
      edges {
        node {
          _rawImages
          images {
            ... on SanityImageAlt {
              _key
              _type
              asset {
                url
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          _id
          client
        }
      }
    }
  }
`
