import React, { useState, useEffect, useRef } from "react"
import classnames from "classnames"

import "./img.css"

const Img = ({ src, srcset }) => {
  const imgReference = useRef()

  const [isLoaded, setIsLoaded] = useState(false)

  const handleLoad = () => {
    setIsLoaded(true)
  }

  const handleError = () => {
    setIsLoaded(false)
  }

  useEffect(() => {
    if (imgReference.current.complete) setIsLoaded(true)
  }, [])

  return (
    <img
      ref={imgReference}
      src={src}
      srcSet={srcset}
      onLoad={handleLoad}
      onError={handleError}
      className={classnames("image", { loaded: isLoaded })}
    />
  )
}

export default Img
